export interface ExampleGroup {
    title: string;
    examples: ExampleQuestion[];
}

export interface ExampleQuestion {
    description: string;
    passage: string;
    question: string;
}

export const exampleGroups: ExampleGroup[] =
 [
  {title:'Inheritance with overrides', examples: [
     {
     description: "Classic example of inheritance with overrides.",
     passage: "Penguins are birds.\n Birds can fly.\n Penguins cannot fly.\n Tweety is a penguin.\n Rachel is a bird.",
     question: "Tweety can fly.\n Rachel can fly.\n Penguins can fly."
     },
     {
     description: "Classic example (with inference rule)",
     passage: "Penguins are birds.\n Sparrows are birds.\n Birds can fly.\n Penguins cannot fly.\n If a bird can fly then it builds its nest in trees.\n If a bird does not fly then it builds its nest on the ground.",
     question: "Penguins lay their nest in trees.\n Penguins lay their nest on the ground.\n Sparrows lay their nest in trees.\n Sparrows lay their nest on the ground."
     },
     {
     description: "More complex example (1)",
     passage: "People are animals.\n Children are people.\n Boys are children.\n Dogs are animals.\n Animals have four legs.\n Animals have tails.\n People do not have tails.\n People have two legs.\n People can drive.\n Children cannot drive.",
     question: "Boys have tails.\n Dogs have tails.\n Children have four legs.\n Children have two legs.\n People can drive.\n Dogs can drive.\n Children can drive.\n Boys can drive."
     },
     {
     description: "More complex example (variant of Brachman's 'I lied about the trees' 1985 paper)",
     passage: "Elephants are animals.\n Animals have four legs.\n Dogs are animals.\n Elephants are grey.\n Clyde is an elephant.\n Elephants have trunks.\n Clyde is not grey.\n Clyde is yellow.",
     question: "Clyde is grey.\n Clyde is yellow.\n Clyde has a trunk.\n Clyde is an elephant.\n Clyde is an animal.\n Dogs have trunks."
     }
     ]},
  {title: 'Examples from the datasets', examples: [
     {
     description: "Inheritance, rules, and negation.",
     passage: "Animals are kind.\n Chimpanzees are kind.\n Insects are not big.\n Mammals are not kind.\n If a chimpanzee is young then the chimpanzee is red.\n If a mammal is not big then the mammal is young.\n If a mammal is red then the mammal is not big.",
     question: "Chimpanzees are young.\n Chimpanzees are red.\n Chimpanzees are not big.\n Insects are not young."
     }
     ]},
  {title: 'Conductivity and counterfactuals', examples: [
        {
    description: "Nails are made of iron, which is a metal, which conducts electricity. Ask: Nails conduct electricity.",
    passage: "Metals conduct electricity. \nInsulators do not conduct electricity. \nIf something is made of iron then it is metal. \nNails are made of iron.",
    question: "Nails conduct electricity."
        },
        {
    description: "Now suppose iron is an insulator. Ask: Nails conduct electricity.",
    passage: "Metals conduct electricity. \nInsulators do not conduct electricity. \nIf something is made of iron then it is an insulator. \nNails are made of iron.",
    question: "Nails conduct electricity."
    },
        {
    description: "Now suppose nails were made of plastic. Ask: Nails conduct electricity.",
    passage: "Metals conduct electricity. \nInsulators do not conduct electricity. \nIf something is made of iron then it is metal. \nNails are made of plastic.",
    question: "Nails conduct electricity."
    },
    {
    description: "Now suppose nails were made of plastic AND plastic is a metal. Ask: Nails conduct electricity.",
    passage: "Metals conduct electricity. \nInsulators do not conduct electricity. \nIf something is made of iron then it is a metal. \nNails are made of plastic. \nPlastic is a metal.",
    question: "Nails conduct electricity."
    }
      ]},
  {title: 'Harry Potter (imaginary worlds)', examples: [
        {
    description: "Harry can do magic, and if you can do magic you can vanish. Ask: Harry can vanish. Mr Dursley can vanish.",
    passage: "Harry can do magic. \nMuggles cannot do magic. \nIf a person can do magic then they can vanish. \nMr Dursley is a Muggle.",
    question: "Harry can vanish. \nMr Dursley can vanish."
  }
      ]},
  {title: 'UK tax (adapted from www.aclweb.org/anthology/P19-1223.pdf)', examples: [
        {
    description: "A few paraphrased statements of UK tax law. If you're French but have a UK civil service pension, do you pay UK tax?",
    passage: "If someone is not a UK resident and they do not have a UK civil service pension then they do not pay UK pension tax. \nIf someone has a UK civil service pension then they pay pension tax in the UK. \nIf someone is a UK resident then they pay pension tax in the UK. \nIf someone's home country is UK then they are a UK resident. \nIf someone's home country is France then they are a French resident. \nJohn's home country is UK. \nPierre's home country is France. \nAlan's home country is France. \nAlan has a UK civil service pension.",
    question: "John pays UK pension tax. \nPierre pays UK pension tax. \nAlan pays UK pension tax."
        }
      ]},
  {title: 'Examples from the paper: The Birds Rulebase', examples: [
        {
    description: "Ostriches and wounded birds can't fly. Can Bill the ostrich fly?",
    passage: "Arthur is a bird. \nArthur is not wounded. \nBill is an ostrich. \nColin is a bird. \nColin is wounded. \nDave is not an ostrich. \nDave is wounded. \nIf someone is an ostrich then they are a bird. \nIf someone is an ostrich then they are abnormal. \nIf someone is an ostrich then they cannot fly. \nIf someone is a bird and wounded then they are abnormal. \nIf someone is wounded then they cannot fly. \nIf someone is a bird and not abnormal then they can fly.",
    question: "Arthur can fly. \nBill can fly. \nColin can fly. \nDave can fly."
        },
        {
    description: "Now suppose birds typically don't fly, but ostriches can fly. Can Bill the ostrich now fly?",
    passage: "If someone is an ostrich then they can fly. \nIf someone is an ostrich then they are a bird. \nIf someone is a bird and not abnormal then they cannot fly. \nIf someone can fly then they are abnormal. \nArthur is a bird. \nBill is an ostrich.",
    question: "Arthur can fly. \nBill can fly."
        }
      ]},
  {title: 'Examples from the paper: Electricity rulebase 1', examples: [
        {
    description: "If an electrical circuit has a switch and a bell, and the switch is on, will the bell ring?",
    passage: "The circuit has a switch. \nThe circuit has a bell. \nThe switch is on. \nIf the circuit has the switch and the switch is on then the circuit is complete. \nIf the circuit does not have the switch then the circuit is complete. \nIf the circuit is complete and the circuit has the light bulb then the light bulb is glowing. \nIf the circuit is complete and the circuit has the bell then the bell is ringing. \nIf the circuit is complete and the circuit has the radio then the radio is playing.",
    question: "The bell is ringing. \nThe light bulb is glowing. \nThe radio is playing."
        }
      ]},
  {title: 'Examples from the datasets', examples: [
        {
    description: "Attributes with negation: Gary is blue. If something is not round then it is cold. Cold things are quiet. Ask: Gary is quiet.",
    passage: "Gary is blue. \nIf something is not round then it is cold. \nCold things are quiet.",
    question: "Gary is quiet."
        },
        {
    description: "Relations with negation: The tiger eats the bear... If something eats the bear then it is red. Etc... Ask: The squirrel likes the tiger.",
    passage: "The squirrel is young. \nThe tiger is rough. \nThe tiger eats the bear. \nIf something eats the bear then it is red. \nIf something is red and rough then the squirrel likes the tiger.",
    question: "The squirrel likes the tiger."
        }
      ]}
];
