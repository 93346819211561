import * as React from 'react';
import styled from 'styled-components';
import { RouteComponentProps } from 'react-router';
import { Button, Select, Input } from '@allenai/varnish/components';

import { Error, Loading, PredictionInfo } from '../components';
import { predict, Prediction, Query } from '../api';
import {
    exampleGroups,
    ExampleGroup,
    ExampleQuestion
} from '../data/examples';

import { Select as AntSelect } from 'antd';

const { Option } = AntSelect;

/**
 * We use a state machine to capture the current state of the view. Since
 * there's a form it might be empty, loading, displaying an answer or
 * giving the user feedback about an error.
 *
 * Feel free to preserve this constructor, or roll your own solution. Do
 * what works best for your use case!
 */
enum View {
    EMPTY,
    LOADING,
    ANSWER,
    ERROR
}

/**
 * The home page has a form, which requires the preservation of state in
 * memory. The links below contain more information about component state
 * and managed forms in React:
 *
 * @see https://reactjs.org/docs/state-and-lifecycle.html
 * @see https://reactjs.org/docs/forms.html
 *
 * Only use state when necessary, as in-memory representaions add a bit of
 * complexity to your UI.
 */
interface State {
    query: Query;
    view: View;
    prediction?: Prediction;
    error?: string;
}

export default class Home extends React.PureComponent<RouteComponentProps, State> {
    constructor(props: RouteComponentProps) {
        super(props);
        this.state = {
            query: Query.fromQueryString(props.location),
            view: View.EMPTY
        };
    }

    /**
     * Returns whether there is an answer and whether that answer is for
     * the current query.
     *
     * @returns {boolean}
     */
    hasAnswerForCurrentQuery() {
        return (
            this.state.prediction &&
            this.state.prediction.context === this.state.query.passage &&
            this.state.prediction.question_raw === this.state.query.question
        );
    }

    /**
     * Submits an API query for an answer for the current query.
     *
     * @returns {void}
     */
    fetchPrediction() {
        // We store a local variable capturing the value of the current
        // query. We use this as a semaphore / lock of sorts, since the
        // API query is asynchronous.
        const originalQuery = this.state.query;
        this.setState({ view: View.LOADING }, () => {
            predict(this.state.query)
                .then(answer => {
                    // When the API returns successfully we make sure that
                    // the returned answer is for the last submitted query.
                    // This way we avoid displaying an answer that's not
                    // associated with the last query the user submitted.
                    if (this.state.query.equals(originalQuery)) {
                        this.setState({
                            view: View.ANSWER,
                            error: undefined,
                            prediction: answer
                        });
                    }
                })
                .catch(err => {
                    // Again, make sure that the error is associated with the
                    // last submitted query.
                    if (this.state.query.equals(originalQuery)) {
                        // Try to see if there's a more specific error
                        // we're supposed to display.
                        let error;
                        if (err.response) {
                            // If the check below is true, the API returned
                            // error message that's likely helpful to display
                            if (err.response.data && err.response.data.error) {
                                error = err.response.data.error;
                            } else if (err.response.status === 503) {
                                error =
                                    'Our system is a little overloaded, ' +
                                    'please try again in a moment';
                            }
                        }

                        // Fallback to a general error message
                        if (!error) {
                            error = 'Something went wrong. Please try again.';
                        }
                        this.setState({
                            view: View.ERROR,
                            prediction: undefined,
                            error
                        });
                    }
                });
        });
    }

    handlePassageChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        const value = event.target.value;
        this.setState(state => ({
            query: new Query(value, state.query.question)
        }));
    };

    handleQuestionChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        const value = event.target.value;
        this.setState(state => ({
            query: new Query(state.query.passage, value)
        }));
    };

    handleExampleSelection = (eventValue: any) => {
        // cast event value to a string
        const value = eventValue as string;

        // parse string as a JSON serialization of an ExampleQuestion
        const exampleQuestion = JSON.parse(value) as ExampleQuestion;

        // set the state
        this.setState({
            query: new Query(exampleQuestion.passage, exampleQuestion.question)
        });
    };

    /**
     * This handler is invoked when the form is submitted, which occurs when
     * the user clicks the submit button or when the user clicks input while
     * the button and/or a form element is selected.
     *
     * We use this instead of a onClick button on a button as it matches the
     * traditional form experience that end users expect.
     *
     * @see https://reactjs.org/docs/forms.html
     */
    handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        // By default, HTML forms make a request back to the server. We
        // prevent that and instead submit the request asynchronously.
        event.preventDefault();

        // We add the query params to the URL, so that users can link to
        // our demo and share noteworthy cases, edge cases, etc.
        this.props.history.push(`/?${this.state.query.toQueryString()}`);

        // Query the answer and display the result.
        this.fetchPrediction();
    };

    /**
     * This is a lifecycle function that's called by React after the component
     * has first been rendered.
     *
     * We use it here to fetch an answer if the url parameters include a fully
     * defined query.
     *
     * You can read more about React component's lifecycle here:
     * @see https://reactjs.org/docs/state-and-lifecycle.html
     */
    componentDidMount() {
        if (this.state.query.isValid() && !this.hasAnswerForCurrentQuery()) {
            this.fetchPrediction();
        }
    }

    makeExampleOptionGroup(group: ExampleGroup): JSX.Element {
        const items: JSX.Element[] = [];
        for (const e of group.examples) {
            items.push(<Option value={JSON.stringify(e)}>{e.description}</Option>);
        }
        return <Select.OptGroup label={group.title}>{items}</Select.OptGroup>;
    }

    /**
     * The render method defines what's rendered. When writing yours keep in
     * mind that you should try to make it a "pure" function of the component's
     * props and state.  In other words, the rendered output should be expressed
     * as a function of the component properties and state.
     *
     * React executes render whenever a component's properties and/or state is
     * updated. The output is then compared with what's rendered and the
     * required updates are made. This is to ensure that rerenders make as few
     * changes to the document as possible -- which can be an expensive process
     * and lead to slow interfaces.
     */
    render() {
        return (
            <React.Fragment>
                <p>RuleTaker determines whether statements are <b>True</b> or <b>False</b> based on
                rules given in natural language. This version is trained on mix of original data and new "propositional" data</p>
                <InputLabel>Select an example:</InputLabel>
                <Select onChange={this.handleExampleSelection} placeholder="Select an example">
                    {exampleGroups.map(this.makeExampleOptionGroup)}
                </Select>

                <Form onSubmit={this.handleSubmit}>
                    <InputLabel>Facts and rules (you can provide your own):</InputLabel>
                    <Input.TextArea
                        placeholder={`Enter a set of facts and rules, like "Bob is blue. If someone is blue then they are rough."`}
                        autosize={{ minRows: 8, maxRows: 10 }}
                        required={true}
                        value={this.state.query.passage}
                        onChange={this.handlePassageChange}
                    />
                    <InputLabel>Is it true?</InputLabel>
                    <Input.TextArea
                        placeholder={`Enter a statement, like "Bob is rough."`}
                        autosize={{ minRows: 3, maxRows: 4 }}
                        value={this.state.query.question}
                        required={true}
                        onChange={this.handleQuestionChange}
                    />
                    <SubmitContainer>
                        <SubmitButton>Submit</SubmitButton>
                        {this.state.view === View.LOADING ? <Loading /> : null}
                        {this.state.view === View.ERROR && this.state.error ? (
                            <Error message={this.state.error} />
                        ) : null}
                    </SubmitContainer>
                </Form>
                {this.state.view === View.ANSWER && this.state.prediction ? (
                    <PredictionInfo prediction={this.state.prediction} />
                ) : null}
            </React.Fragment>
        );
    }
}

/**
 * The definitions below create components that we can use in the render
 * function above that have extended / customized CSS attached to them.
 * Learn more about styled components:
 * @see https://www.styled-components.com/
 *
 *
 * CSS is used to modify the display of HTML elements. If you're not familiar
 * with it here's quick introduction:
 * @see https://developer.mozilla.org/en-US/docs/Web/CSS
 */

const Form = styled.form`
    margin: ${({ theme }) => `0 0 ${theme.spacing.sm}`};
    max-width: 600px;
`;

const SubmitButton = styled(Button).attrs({
    htmlType: 'submit',
    variant: 'primary'
})`
    margin: ${({ theme }) => `${theme.spacing.md} ${theme.spacing.md} ${theme.spacing.md} 0`};
`;

const SubmitContainer = styled.div`
    display: grid;
    grid-template-columns: min-content min-content;
    grid-gap: ${({ theme }) => `${theme.spacing.xs} 0 0`};
    align-items: center;
`;

const InputLabel = styled.div`
    margin-top: ${({ theme }) => theme.spacing.sm};
    margin-bottom: ${({ theme }) => theme.spacing.xxs};
`;
