import queryString from 'querystring';
import { Location } from 'history';
import { unwrap } from '../utils';

/**
 * Representation of a query, which consists of a passage and a question.
 */
export class Query {
    constructor(readonly passage: string = '', readonly question: string = '') {}

    /**
     * Returns whether the current query has a non-empty question and two
     * non-empty answers.
     *
     * @return {boolean}
     */
    isValid() {
        return this.passage.trim() !== '' && this.question.trim() !== '';
    }

    /**
     * In JavaScript (and TypeScript) object comparison is by reference. We
     * provide an equals value as to compare two instances, allowing us to
     * determine if they're logically equal
     *
     * @param {Query} query
     *
     * @returns {boolean} True if the two queries are logically equal (their
     * values are the same). False if not.
     */
    equals(query: Query): boolean {
        return this.passage === query.passage && this.question === query.question;
    }

    /**
     * Serializes the query to a URL appropriate representation.
     *
     * @returns {string}
     */
    toQueryString(): string {
        return queryString.stringify({ p: this.passage, q: this.question });
    }

    /**
     * Factory that returns a Query instance based upon the provided URL.
     *
     * @param {Location} location
     *
     * @returns {Query}
     */
    public static fromQueryString(location: Location): Query {
        const locationSearchWithoutQuestionmark = location.search.replace(/^\?/, '');
        const qs = queryString.parse(locationSearchWithoutQuestionmark);
        console.log('qs is', qs);
        console.log('passage is', qs.p);
        return new Query(unwrap(qs.p), unwrap(qs.q));
    }
}
